<template>
  <div style="margin-top:-83rem">
    <div class="title">成长值</div>
    <div class="chart_wrap">
      <Chart1 :myId="'chart1'+isDialogId"
              :xValues="growNumX"
              :dataMax="growNumMax"
              :dataSelf="growNumSelf"
              :maxYValue="maxYValue" />
    </div>
    <div class="title">得分率</div>

    <div class="chart_wrap">
      <Chart2 :myId="'chart2'+isDialogId"
              :xValues='rateDataX'
              :dataAvg="rateDataAvg"
              :dataUser="rateDataUser"
              :dataMax="rateDataMax" />
    </div>

  </div>
</template>

<script>
import Chart1 from './charts/mychart1.vue'
import Chart2 from './charts/myChart2.vue'
import { getScoreDetail, getGrowData } from '@/api/userInfo.js'
export default {
  components: {
    Chart1, Chart2
  },
  mounted () {
    // this.getRate()
    // this.getGrowNum()
    this.getData()
  },
  props: {
    isDialogId: {
      type: String,
      default: "00"
    }
  },
  data () {
    return {
      rateData: [],
      rateDataX: [],
      rateDataAvg: [],
      rateDataUser: [],
      rateDataMax: [],

      growNum: [],
      growNumX: [],
      growNumSelf: [],
      growNumMax: [],
      maxYValue: 0
    }
  },
  methods: {
    async getRate () {
      const { data } = await getScoreDetail()
      this.rateData = data.result
      // console.log(this.rateData[0])
      for (let i = 0; i < this.rateData.length; i++) {
        if (this.rateData[i].subject_id == 13) {
          continue
        }
        else {
          this.rateDataX.push(this.rateData[i].subject_name)
          this.rateDataAvg.push(this.rateData[i].avg_score)
          this.rateDataUser.push(this.rateData[i].user_score)
          this.rateDataMax.push(this.rateData[i].max_score)
        }
        // this.rateDataX.push(this.rateDate.subject_name)
      }
      // console.log(this.rateDataX, this.rateDataAvg, this.rateDataUser, this.rateDataMax)
    },
    async getGrowNum () {
      const { data } = await getGrowData()
      this.growNum = data.data
      for (let i = 0; i < this.growNum.length; i++) {
        if (this.growNum[i].subject_id == 13) {
          continue
        } else {
          this.growNumX.push(this.growNum[i].subject_name)
          this.growNumMax.push(this.growNum[i].max_user_score)
          this.growNumSelf.push(this.growNum[i].user_score)
        }

        // this.rateDataX.push(this.rateDate.subject_name)
      }
      this.maxYValue = data.max_score
      // console.log(data, this.growNumX, this.growNumMax, this.growNumSelf, 'a')
    },
    async getData () {
      await this.getRate()
      this.getGrowNum()

    }

  }
}
</script>

<style lang="scss" scoped>
.title {
  font-size: 26rem;
  padding-left: 70rem;
}
.chart_wrap {
  background: #fff;
  margin: 10rem 20rem 10rem;
  border-radius: 20rem;
}
</style>