<template>
  <div>
    <!-- <div v-show="xValues&&xValues.length>0"
         :id="myId"
         style="width:100%;height:300rem"></div>
    <div class='no_data'
         v-show="!xValues||xValues.length==0">暂无数据</div> -->
    <div class="title">各科目当前得分率</div>
    <div :id="myId"
         style="width:95%;height:36vh;min-height:200px"></div>
  </div>

</template>

<script>
export default {
  data () {
    return {
      myChart: undefined
    }
  },
  props: ['myId', 'xValues', 'dataAvg', 'dataUser', 'dataMax'],//, 'color', 'series'
  watch: {
    // xValues () {

    //   this.clear()
    //   if (this.xValues && this.xValues.length > 0)
    //     this.$nextTick(() => {
    //       this.drawLine()
    //     })

    // }
    dataMax () {
      this.clear()
      this.$nextTick(() => {
        this.drawLine()
      })
    }

  },
  methods: {
    clear () {
      // let myChart = this.$echarts.init(document.getElementById('myCharts'))
      if (this.myChart) {
        this.myChart.dispose()
      }
    },
    drawLine () {
      this.myChart = this.$echarts.init(document.getElementById(this.myId))
      this.myChart.setOption({

        xAxis: [
          {
            type: 'category',
            // data: ['语文', '数学', '英语', '历史', '政治', '历史', '地理', '生物', '物理'],
            data: this.xValues,
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              fontSize: 14,
              color: '#000', interval: 0
            },
            // splitArea: {

            // }
          }
        ],
        legend: {
          show: true,
          icon: 'circle',
          right: '1%',
        },
        // color: colors,
        yAxis: {
          type: 'value',
          axisLabel: {
            fontSize: 14,
            color: '#000',
          },

          max: 100,
          min: 0,
        },
        tooltip: {
          trigger: 'axis',
          backgroundColor: '#656565',
          borderColor: "rgba(125,125,125,0.5)",
          textStyle: {
            color: "#fff"
          },

        },
        grid: {
          bottom: '20rem',
          right: '10rem',
          left: '30rem',
          containLabel: true
        },
        // series: series.value
        series: [
          {
            name: '最高得分率(%)',
            type: 'bar',
            barGap: '-100%',
            barWidth: '20%',
            barMaxWidth: '30',
            showBackground: true,
            backgroundStyle: {
              color: "rgba(252, 248, 248, 0.9)",
              shadowBlur: 2,
              borderRadius: 40,
              shadowColor: "rgba(167, 167, 167, 0.2)",
              borderWidth: 4,
              borderColor: "rgba(171, 171, 171, 0.1)"
            },
            label: {
              show: true,
              align: "center",
              position: "top"
            },
            itemStyle: {
              borderRadius: 40,
              shadowBlur: 4.5,
              shadowColor: '#72DAE4',
              color: '#72DAE4'
            },
            // xAxisIndex:4,
            // data: [92, 82, 91, 84, 90, 50, 90, 60, 88]
            data: this.dataMax
          },


          {
            name: '平均得分率(%)',
            type: 'bar',
            barGap: '-100%',
            // 
            barWidth: '20%',
            barMaxWidth: '30',
            showBackground: true,
            label: {
              show: true,
              align: "center",
              color: "#fff",
              position: "insideTop",
              textShadowBlur: 2,
              textShadowColor: "#000",
              // formatter: (params) => {
              //   return Number(params.value).toFixed(0);
              // }
            },


            //  }

            backgroundStyle: {
              color: "rgba(252, 248, 248, 0.9)",
              shadowBlur: 2,
              borderRadius: 40,
              shadowColor: "rgba(167, 167, 167, 0.2)",
              borderWidth: 4,
              borderColor: "rgba(171, 171, 171, 0.1)"
            },
            itemStyle: {
              borderRadius: 40,
              color: "#A2ACFD",
              shadowBlur: 4.5,
              shadowColor: '#A2ACFD',
            },
            // data: [60, 52, 71, 74, 10, 20, 10, 30, 66]
            data: this.dataAvg
          },
          {
            name: '当前得分率(%)',
            type: 'bar',
            // barWidth: 22,
            barWidth: '20%',
            barMaxWidth: '30',
            barGap: '-100%',
            showBackground: true,
            label: {
              show: true,
              align: "center",
              color: "#fff",
              position: "insideTop",
              textShadowBlur: 2,
              textShadowColor: "#000",

            },

            backgroundStyle: {
              color: "rgba(252, 248, 248, 0.9)",
              shadowBlur: 2,
              borderRadius: 40,
              shadowColor: "rgba(167, 167, 167, 0.2)",
              borderWidth: 4,
              borderColor: "rgba(171, 171, 171, 0.1)"
            },
            // data: [60, 42, 60, 73, 10, 13, 11, 50, 20, 30],
            data: this.dataUser,
            itemStyle: {
              borderRadius: 40,
              color: "#359EF2",
              shadowBlur: 4.5,
              shadowColor: '#359EF2',
            }
          },

        ]
      })
      window.addEventListener('resize', this.resizeChart)

    },
    resizeChart () {
      this.myChart.resize()
    }

  },
  destroyed () {
    window.removeEventListener('resize', this.resizeChart)
  },
  mounted () {
    // this.drawLine()
    //  this.clear()
    // this.drawLine()
    //  this.$nextTick(() => this.drawLine())
  }
}
</script>

<style lang='scss' scoped>
.no_data {
  text-align: center;
  line-height: 200rem;
  color: #ccc;
}
.title {
  text-align: center;
  font-size: 24rem;
  padding: 10rem 15rem 8rem;
}
// .chart_wrap {
//   background: #fff;
//   // margin: 20rem;
//   border-radius: 22rem;
// }
</style>












