<template>
  <div>
    <!-- <div v-show="xValues&&xValues.length>0"
         :id="myId"
         style="width:100%;height:300rem"></div>
    <div class='no_data'
         v-show="!xValues||xValues.length==0">暂无数据</div> -->
    <div class="title">各科目当前成长值</div>
    <div :id="myId"
         style="width:95%;height:36vh;min-height:200px"></div>
  </div>

</template>

<script>
export default {
  data () {
    return {
      myChart: undefined,
      // topDataList: [92, 82, 91, 84, 90, 50, 90, 60, 88]
    }
  },
  props: ['myId', 'xValues', 'dataMax', 'dataSelf', 'maxYValue'],//, 'color', 'series'
  watch: {
    dataSelf () {
      this.clear()
      this.$nextTick(() => {
        this.drawLine()
      })
    }
  },
  computed: {
    topDataListPoints () {
      let arr = []
      if (this.dataMax) {
        for (let i = 0; i < this.dataMax.length; i++) {
          arr.push({
            coord: [i, this.dataMax[i]]
          })
        }
      }

      return arr
    }
  },
  methods: {
    clear () {
      // let myChart = this.$echarts.init(document.getElementById('myCharts'))
      if (this.myChart) {
        this.myChart.dispose()
      }
    },


    drawLine () {

      this.myChart = this.$echarts.init(document.getElementById(this.myId))
      this.myChart.setOption({

        xAxis: [
          {
            type: 'category',
            // data: ['语文', '数学', '英语', '历史', '政治', '历史', '地理', '生物', '物理'],
            data: this.xValues,
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              fontSize: 14,
              color: '#000'
            }

          }
        ],
        legend: {
          show: true,
          icon: 'circle',
          right: '1%',
        },
        // color: colors,
        yAxis: {
          type: 'value',
          axisLabel: {
            fontSize: 14,
            color: '#000'
          },
          max: this.maxYValue,
          // min: 0,
        },
        tooltip: {
          trigger: 'axis',
          backgroundColor: '#656565',
          borderColor: "rgba(125,125,125,0.5)",
          textStyle: {
            color: "#fff"
          }
        },
        grid: {
          bottom: '10rem',
          right: '10rem',
          left: '30rem',
          containLabel: true
        },
        // series: series.value
        series: [
          {
            type: 'custom',
            name: '科目最高',
            type: 'bar',
            // barWidth: 22,
            barWidth: '5%',

            barGap: '-100%',

            label: {
              show: true,
              align: "center",
              color: "#999",
              position: "top",
              opacity: 1
            },

            markPoint: {
              // data: [92, 82, 91, 84, 90, 50, 90, 60, 88],
              // data: [
              //   { coord: [0, 92] },
              //   { coord: [1, 82] },],
              data: this.topDataListPoints,
              symbolSize: 11,
              symbol: "circle",
              itemStyle: {
                borderWidth: 3,
                borderColor: '#fff',
                shadowBlur: 3,
                shadowColor: 'rgba(0, 0, 40, 0.2)'
              }
            },
            itemStyle: {
              borderRadius: 40,
              color: "#6CB9F5",
              shadowBlur: 2,
              shadowColor: '#6CB9F5',
              opacity: 0.5
            },
            data: this.dataMax

          },
          {
            name: '自身科目',
            type: 'bar',
            barGap: '-100%',
            barWidth: '5%',


            label: {
              show: true,
              align: "center",
              position: "top",
              color: '#999'
            },
            itemStyle: {
              borderRadius: 40,
              shadowBlur: 2,
              shadowColor: '#72DAE4',
              color: '#72DAE4'
            },
            // xAxisIndex:4,
            // data: [60, 42, 60, 73, 10, 13, 11, 50, 20, 30],
            data: this.dataSelf
          },

        ]
      })
      window.addEventListener('resize', this.resizeChart)

    },
    resizeChart () {
      this.myChart.resize()
    }

  },
  destroyed () {
    window.removeEventListener('resize', this.resizeChart)
  },
  mounted () {
    this.clear()
    // this.drawLine()
    this.$nextTick(() => this.drawLine())
  }
}
</script>

<style lang='scss' scoped>
.no_data {
  text-align: center;
  line-height: 200rem;
  color: #ccc;
}
.title {
  text-align: center;
  font-size: 24rem;
  padding: 10rem 15rem 8rem;
}
// .chart_wrap {
//   background: #fff;
//   // margin: 20rem;
//   border-radius: 22rem;
// }
</style>












